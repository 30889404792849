import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import loadable from '@loadable/component';
import styled from "styled-components"

const HeroHomeTextSample = loadable(() => import('./hero-home-text-sample'));

const HeroHomeWithImageBackgroundSample = () => {
  const data = useStaticQuery(graphql`
    query HeroHomeWithImageBackgroundSampleQuery {           
      imageBg: file(relativePath: { eq: "backgrounds/home-hero-bg-new.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            height: 860
            formats: [WEBP]
          )
        }
      }
    }
  `)
  
  const imageBg = getSrc(data.imageBg)

  const StyledDiv = styled.div`
    width: auto;
    height: 100vh;
    background-image: url(${imageBg}); /* The image used */
    background-color: #333333 /* Used if the image is unavailable */
    /* height: 500px;  You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    opacity: 0.7;
    }
  `

  return (
    <div>
      <Helmet>
        <link rel="preload" as="image" href={imageBg} />
      </Helmet>

      <StyledDiv className="relative"></StyledDiv>          

      <HeroHomeTextSample />
    </div>
  )
}

export default HeroHomeWithImageBackgroundSample